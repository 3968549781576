import Translations from "@/lib/Translations";
const $t = Translations.Get;

const PG = {};

PG.GetBMIClassification = (bmi) => {
    if (bmi < 18.5) {
        return $t("Underweight");
    } else if (bmi >= 18.5 && bmi < 25) {
        return $t("Healthy weight");
    } else if (bmi >= 25 && bmi < 30) {
        return $t("Overweight");
    } else if (bmi >= 30 && bmi < 35) {
        return $t("Fairly overweight");
    } else if (bmi >= 35) {
        return $t("Extremely overweight");
    }

    return "";
};

PG.GetRequiredWeightForBmi = (user_length, bmi) => {
    let weight = bmi * ((user_length / 100) * (user_length / 100));
    return Math.round(weight * 10) / 10;
};

PG.CalculateBMI = (weight, height) => {
    const length = height / 100;

    return Math.round((weight / (length * length)) * 100) / 100;
};

PG.CalculateKcalNeed = (weight, height, age, gender, activity) => {
    const GENDERS = {
        0: "Female",
        1: "Male",
        Female: "0",
        Male: "1",
    };

    weight = parseInt(weight);
    height = parseInt(height);
    age = parseInt(age);
    activity = parseInt(activity);

    let out = {
        fields: {
            weight,
            height,
            age,
            gender,
            activity,
        },
        activities: {},
    };

    let kcalRest;
    if (gender == GENDERS.Female) {
        kcalRest = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
    } else {
        kcalRest = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
    }

    out.activities = {
        none: Math.round(kcalRest * 1.2),
        small: Math.round(kcalRest * 1.375),
        medium: Math.round(kcalRest * 1.55),
        large: Math.round(kcalRest * 1.725),
    };

    let kcalActivity;
    if (activity == 0) {
        kcalActivity = out.activities.none;
    } else if (activity == 2) {
        kcalActivity = out.activities.small;
    } else if (activity == 4) {
        kcalActivity = out.activities.medium;
    } else if (activity == 6) {
        kcalActivity = out.activities.large;
    }

    out.kcalActivity = kcalActivity - 500;

    return out;
};

PG.ConvertIonicToRoute = (ionic) => {
    switch (ionic) {
        case "menuBmi":
            return "/pagina/bmi";
        case "menuKcal":
            return "/pagina/kcal";
        case "menuDashboard":
            return "/";
        case "menuWeekMenu":
            return "/week-menu";
        case "menuRecipe":
            return "/recepten";
        case "menuMyPg":
            return "/my-pg";
        default:
            return null;
    }
};

export default PG;
